import { Eip1193Provider } from 'ethers'

import { NetworkClass } from './NetworkClass'

export class PolygonNetworkClass extends NetworkClass {
  constructor(
    public chainId: string,
    public name: string,
    public lootboxContractAddress: string,
    public openseaUrl: string,
    public openseaNetwork: string,
    public rpc: string,
    public payingTokenAddress: string,
    public projectName: string,
    public projectDescription: string,
    public imgSquare: string,
    public chainLogo: string,
    public gemFilterNames: [string, string, string, string],
    public isSoldOut: boolean,
    public soldOutMessage: string,
    public isUpcoming: boolean
  ) {
    super(
      chainId,
      name,
      lootboxContractAddress,
      openseaUrl,
      openseaNetwork,
      rpc,
      payingTokenAddress,
      projectName,
      projectDescription,
      imgSquare,
      chainLogo,
      gemFilterNames,
      isSoldOut,
      soldOutMessage,
      isUpcoming
    )
  }

  addNetwok(extension: Eip1193Provider) {
    return extension.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x89',
          rpcUrls: ['https://polygon-rpc.com/'],
          chainName: 'Polygon Mainnet',
          nativeCurrency: {
            name: 'POL',
            symbol: 'POL',
            decimals: 18,
          },
          blockExplorerUrls: ['https://polygonscan.com'],
        },
      ],
    })
  }
}
