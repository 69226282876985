import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom'
import 'react-modern-drawer/dist/index.css'
import { useState } from 'react'

import { Header } from 'components/Header'
import { Lootbox } from 'routes/Lootbox'
import { Jackpot } from 'routes/Jackpot'
import { Gems } from 'routes/Gems'
import { LootboxRoutes } from 'types/routes'
import { WalletProvider } from 'context/Wallet'
import { DataProvider } from 'context/Data'
import { DrawerProvider } from 'context/Drawer'
import { PrizesFlowProvider } from 'context/PrizesFlow'
import { OverlayProvider, useOverlayContext } from 'context/Overlay'
import { NetworkSpeedProvider } from 'context/NetworkSpeed'
import { ToastProvider } from 'context/Toast'
import { Vault } from 'routes/Vault'
import { History } from 'routes/History'
import { Footer } from 'components/Footer'
import { SoundBtn } from 'components/SoundBtn'
import { Projects } from 'routes/Projects'
import { ScrollToTop } from 'components/ScrollToTop'
import { SwissborgInfo } from 'components/SwissborgInfo'

function Main() {
  const [isVisible, setIsVisible] = useState(true)
  const { isSoundOn } = useOverlayContext()

  const handleVideoEnd = () => {
    setIsVisible(false)
  }

  return (
    <>
      {isVisible ? (
        <div
          className={`fixed top-0 left-0 w-screen h-screen bg-black z-[60] overflow-x-hidden overflow-y-scroll ${
            !isVisible ? 'opacity-0 transition-opacity duration-500' : ''
          }`}
        >
          <div className="flex flex-col items-center justify-center h-full">
            <video
              src="/promo.mp4"
              autoPlay
              muted={!isSoundOn}
              preload="auto"
              onEnded={handleVideoEnd}
            />
          </div>
          <div className="fixed left-5 bottom-5 z-[70]">
            <SoundBtn />
          </div>
          <button
            type="button"
            onClick={handleVideoEnd}
            className={`${!isVisible ? 'hidden' : ''}`}
          >
            <p className="absolute top-5 right-5 font-mont text-pampas text-[12px]">
              Skip
            </p>
          </button>
        </div>
      ) : null}
      <div className="flex-grow flex flex-col lootbox-md:mt-11">
        <ScrollToTop />
        <Outlet />
      </div>
    </>
  )
}

function App() {
  return (
    <BrowserRouter>
      <NetworkSpeedProvider>
        <ToastProvider>
          <WalletProvider>
            <OverlayProvider>
              <DataProvider>
                <DrawerProvider>
                  <PrizesFlowProvider>
                    <div className="flex flex-col min-h-screen ">
                      {/*<SwissborgInfo />*/}
                      <div className="px-5 navigation-md:px-[72px] flex-1 flex flex-col">
                        <Header />
                        <Routes>
                          <Route element={<Main />}>
                            <Route
                              path="/"
                              element={
                                <Navigate to={`/${LootboxRoutes.box}`} />
                              }
                            />
                            <Route
                              path={`/${LootboxRoutes.box}`}
                              element={<Lootbox />}
                            />
                            <Route
                              path={`/${LootboxRoutes.projects}`}
                              element={<Projects />}
                            />
                            <Route
                              path={`/${LootboxRoutes.gems}`}
                              element={<Gems />}
                            />
                            <Route
                              path={`/${LootboxRoutes.vault}`}
                              element={<Vault />}
                            />
                            <Route
                              path={`/${LootboxRoutes.jackpot}`}
                              element={<Jackpot />}
                            />
                            <Route
                              path={`/${LootboxRoutes.history}`}
                              element={<History />}
                            />
                            <Route path="*" element={<Lootbox />} />
                          </Route>
                        </Routes>
                        <Footer />
                      </div>
                    </div>
                  </PrizesFlowProvider>
                </DrawerProvider>
              </DataProvider>
            </OverlayProvider>
          </WalletProvider>
        </ToastProvider>
      </NetworkSpeedProvider>
    </BrowserRouter>
  )
}

export default App
